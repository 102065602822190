<template>
  <h1 class="hello">PAMPHLET</h1>
  <div class="margin"></div>
  <h2>DE L’OEUVRE</h2>
  <h2>À L’AUDIO</h2>
  <h2>EN QUELQUES MINUTES</h2>
  <div class="margin"></div>
  <div class="d-grid gap-2 col-10 col-xl-6 mx-auto">
    <button type="button" class="btn btn-dark" v-on:click="goToYourName()">JE M’INSCRIS</button>
  </div>
  <div class="margin"></div>
</template>

<script>
export default {
  name: 'FirstSlide',
  methods: {
    goToYourName() {
      var elTitle = document.getElementById("freeSampleTitle")
      elTitle.scrollIntoView()
      var elYourNameInput = document.getElementById("yourNameInput")
      elYourNameInput.select()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h2 {
  margin: 1rem;
  font-size: 1.4rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.margin {
  margin-top: 5rem;
}
.btn-dark {
  letter-spacing: 0.4rem;
  border-radius: 0;
}
</style>
