<template>
  <hr />
  <h2 id="freeSampleTitle">VOTRE ÉCHANTILLON GRATUIT</h2>
  <div class="margin"></div>
  <div class="alert alert-success" role="alert" v-if="displaySendFormSuccess" v-on:click="displaySendFormSuccess = !displaySendFormSuccess">
    Merci pour votre confiance, nous allons vous répondre dans les plus brefs délais.
  </div>
  <div class="alert alert-danger" role="alert" v-if="displaySendFormError" v-on:click="displaySendFormError = !displaySendFormError">
    Une erreur inconnue est survenu.
  </div>
  <div class="margin"></div>
  <form class="row d-grid gap-2 col-10 col-xl-6 mx-auto">
    <div class="mb-3">
      <label for="formGroupExampleInput" class="form-label">VOTRE NOM</label>
      <input type="text" class="form-control" id="yourNameInput" />
    </div>
    <div class="mb-3">
      <label for="formGroupExampleInput2" class="form-label">
        VOTRE EMAIL
      </label>
      <input type="text" class="form-control" id="yourEmailInput" />
    </div>
    <div class="margin"></div>
    <div class="mb-3">
      <label for="formGroupExampleInput" class="form-label">VOTRE TEXTE</label>
      <textarea rows="10" placeholder="150 mots maximum" id="yourTextInput"></textarea>
    </div>
  </form>
  <div class="d-grid gap-2 col-10 col-xl-6 mx-auto">
    <button type="button" class="btn btn-dark" v-on:click="sendForm()">ENVOYER</button>
  </div>
  <div class="margin"></div>
  <p>
    <img class="logo_star" alt="Logo star" src="../assets/star.svg"/>
  </p>
</template>

<script>
export default {
  data: function() {
    return {
      displaySendFormSuccess: false,
      displaySendFormError: false
    }
  },
  name: 'SecondSlide',
  methods: {
    validateName(inputText) {
      var nameformat = /^[a-zA-Z0-9-àÀâẦéÉèÈëËêÊïÏîÎ]{2,50}$/;
      if(inputText.value.match(nameformat)) {
        return true;
      } else {
        alert("Votre nom est invalide.");
        inputText.focus();
        return false;
      }
    },
    validateEmail(inputText) {
      var mailformat = /^(\w{1,2}([.-]?\w+)*@\w{1,2}([.-]?\w+)*(\.\w{2,3}))+$/;
      if(inputText.value.match(mailformat) && inputText.value.length < 51) {
        return true;
      } else {
        alert("Votre adresse email est invalide.");
        inputText.focus();
        return false;
      }
    },
    validateText(inputText) {
      var mailformat = /^\W*(?:\w+\b\W*){10,100}$/;
      if(inputText.value.match(mailformat)) {
        return true;
      } else {
        alert("Votre texte est invalide il doit être compris entre 10 et 150 mots.");
        inputText.focus();
        return false;
      }
    },
    sendForm() {
      var elYourNameInput = document.getElementById("yourNameInput")
      var elYourEmailInput = document.getElementById("yourEmailInput")
      var elYourTextInput = document.getElementById("yourTextInput")

      if (this.validateName(elYourNameInput) && this.validateEmail(elYourEmailInput) && this.validateText(elYourTextInput)) {
        this.axios.post("https://api.red-dev.fr/v1/pamphlet/subscribe",
          {
            headers: {
              'Access-Control-Allow-Origin': true,
            },
            name: elYourNameInput.value.toLowerCase(),
            email: elYourEmailInput.value.toLowerCase(),
            text: elYourTextInput.value.toLowerCase(),
          }
        ).then(res => {
          if (res.status == 200) {
            this.displaySendFormSuccess = true
          } else {
            this.displaySendFormError = true
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h2 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.btn-dark {
  margin-top: 4rem;
  letter-spacing: 0.4rem;
  border-radius: 0;
}
textarea {
  margin-top: 2rem;
  width: 100%;
  background: transparent;
  border: 2px solid #000000;
}
input[type="text"] {
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #000000;
}
img.logo_star {
  height: 8rem;
  width: 8rem;
}
input[type="text"].form-control:focus {
  background-color: transparent;
  border-color: black;
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus, input:focus{
  outline: none;
}
</style>
