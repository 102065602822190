<template>
  <router-view />
</template>

<style lang="scss">
body {
  background-color: #f0f0f0;
}
@font-face {
  font-family: montserra_font;
  src: url("../src/assets/Montserrat/static/Montserrat-Bold.ttf");
}

$font-family-base: montserra_font;

body {
  text-align: center;
}

#app {
  background-color: #f0f0f0;
  font-family: montserra_font, Helvetica, Arial, sans-serif;
  letter-spacing: 0.8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 2rem;
  padding-bottom: 4rem;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
