<template>
  <div class="home">
    <img class="logo" alt="Logo pamphlet" src="../assets/logo.svg" />
    <p>
      <img
        class="logo_auto_edition"
        alt="Logo auto-édition"
        src="../assets/auto_edition.png"
      />
    </p>
    <FirstSlide />
    <hr />
    <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-xxl-4 text-center">
        <div class="book book1 text-center">
        <br>
        <br>
        <h3 class="bookTitle">LES MISÉRABLES</h3>
        <br>
        <h4 class="bookAuthor">Victor Hugo</h4>
        <p>
          <img class="logo_star" alt="Logo star" src="../assets/star.svg" />
        </p>
        <br>
        <audio ref="audio" src="./audio/miserables.mp3" controls />
      </div>
      </div>
      <div class="col-12 col-md-6 col-xxl-4">
        <div class="book book2">
        <br>
        <h3 class="bookTitle">20000 <p>LIEUES SOUS LES MERS</p></h3>
        <h4 class="bookAuthor">Jules Vernes</h4>
        <p>
          <img class="logo_star" alt="Logo star2" src="../assets/star2.svg" />
        </p>
        <br>
        <audio ref="audio" src="./audio/20000.mp3" controls />
      </div>
      </div>
      <div class="col-12 col-md-6 col-xxl-4">
        <div class="book book3">
        <br>
        <h3 class="bookTitle">LES AVENTURES D’ALICE AU PAYS DES MERVEILLES</h3>
        <h4 class="bookAuthor">Lewis Carroll</h4>
        <p>
          <img class="logo_star" alt="Logo star" src="../assets/star.svg" />
        </p>
        <br>
        <audio ref="audio" src="./audio/alice.mp3" controls />
      </div>
      </div>
      </div>
    </div>
    <SecondSlide />
  </div>
</template>

<script>
import FirstSlide from '@/components/FirstSlide.vue'
import SecondSlide from '@/components/SecondSlide.vue'

export default {
  name: 'HomeView',
  components: {
    FirstSlide,
    SecondSlide
  }
}
</script>

<style lang="scss">
div.container {
  max-width: 100%;
}
.logo {
  height: 15rem;
  border: 0.6rem solid black;
  border-top: 0;
  padding: 1.2rem;
}

audio {
  position: relative;
  bottom: 1rem;
}
.logo_auto_edition_book {
  margin: 8rem;
}
.bookTitle {
  margin-bottom: 3rem;
}
.bookAuthor {
  margin-bottom: 2rem;
}
.book {
  margin-bottom: 2rem;
  height: 30rem;
}

.book1 {
  background: #d35f53;
}

.book2 {
  background: #fbb843;
}
.book3 {
  color: white;
  background: #004aad;
}
</style>
